$(function () {
	$('.carousel-hero').flickity({
		contain: false,
		autoPlay: 4700
	});

	$('li.icon-search a').on('click', function(event) {
		event.preventDefault();
		$('#search').addClass('open');
		$('body').addClass('search-open');
		$('#search > .search-content > form > input[type="search"]').focus();
	});

	$('#search, #search button.close').on('click keyup', function(event) {
		if (event.target === this || event.target.className === 'close' || event.keyCode === 27) {
			$(this).removeClass('open');
			$('body').removeClass('search-open');
		}
	});
	$( "#searchform input.search-control" ).autocomplete({
		minLength: 3,
		source: function (request, response) {
			$.ajax({
				type: "POST",
				url: sf_blog.ajaxurl,
				data: "data=" + request.term + "&action=blog_search",
				success: function( data ) {
					response(data); //return the data to the response object
				}
			});
		},
		appendTo: ".search-results", //custom div to append results to
		open: function() {},
		select: function( event, ui ) {
			//when an item is selected change to the url of that object
			window.location.href = $("<div/>").html(ui.item.url).text();
			return false;
		}
	})
	.autocomplete( "instance" )._renderItem = function( ul, item ) {
		//append item to the list using our properties
		return $( "<li>" )
			.append( "<div>" + item.value + "</div>" )
			.appendTo( ul );
	};
});